<template>
  <dash-page-new
      :title="$t('mailer.automations.title')"
      :root="$t('Newsletter')"

  >

  <ws-button label="CampaignDetails" :to="businessDashLink(`fine_mailer/view/campaign-23423`)" />

  </dash-page-new>
</template>

<script>
export default {
  name: "mailerLetters"
}
</script>

<style scoped>

</style>